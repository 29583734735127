<template>
  <div
    :class="['chat-item', { ['chat-item--active']: active }]"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="chat-item__avatar">
      <Avatar>
        <slot>{{ getNameAbbreviation(name) }}</slot>
      </Avatar>
    </div>
    <div class="chat-item__body">
      <div class="chat-item__recipient">
        <div class="chat-item__star" v-if="starred">
          <IconStar/>
        </div>
        <div class="chat-item__name">{{ name }}</div>
        <div v-if="!!unread" class="chat-item__badge">{{ unread }}</div>
      </div>
      <div class="chat-item__message">
        <div class="chat-item__message-icon">
          <IconMsgIn v-if="type === MessageType.OUTBOUND"/>
          <IconMsgOut v-if="type === MessageType.INBOUND"/>
        </div>
        <div :class="['chat-item__text', { ['chat-item__text--unread']: !!unread }]" :style="{ width: messageWidth }">
          <template v-if="!!message">{{ message }}</template>
          <template v-else><span class="chat-item__text--action">{{ formatAction(action) }}</span></template>
        </div>
        <div class="chat-item__date" ref="dateRef">{{ formatDate(date) }}</div>
      </div>
    </div>
    <div v-if="hover" class="chat-item__context-menu" style="display: none;">
      <div class="chat-item__context-menu-btn">
        <IconStar @click.prevent="onStarClick"/>
      </div>
      <div class="chat-item__context-menu-btn">
        <IconRemove @click.prevent="onRemoveClick"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MessageType } from '../stores/chat';

import IconRemove from '../assets/images/icons/remove.svg';
import IconStar from '../assets/images/icons/star-blue.svg';
import IconMsgIn from '../assets/images/icons/msg-in.svg';
import IconMsgOut from '../assets/images/icons/msg-out.svg';

import { computed, ref } from 'vue';
import moment from '../utils/moment';
import Avatar from './Avatar.vue';

type MessageDirection = 'inbound' | 'outbound';

const {
  active = false,
  starred = false,
  unread = 0,
  type = MessageType.INBOUND,
  date,
} = defineProps<{
  id?: any,
  active?: boolean;
  starred?: boolean;
  unread?: number;
  name?: string;
  action?: string;
  message: string;
  type?: MessageType | MessageDirection;
  date: string | Date | moment.Moment;
}>();

const hover = ref(false);
const dateRef = ref<HTMLElement | null>(null);
const messageWidth = computed(() => {
  // NOTE: this ad-hoc is for Windows, since it fails on calculating the width of the message flex container with ellipsis
  const W_CONTAINER = 214;
  const W_ICON = 12;
  const W_GAP = 4 * 2; // 4px gap * 2 columns
  const W_SECURE = 24; // secure the width for the ellipsis calc entropy

  if (dateRef.value) {
    const dateWidth = dateRef.value.clientWidth + W_SECURE;
    const messageWidth = W_CONTAINER - W_ICON - W_GAP - dateWidth;

    return `${messageWidth}px`;
  }

  return 'auto';
})

const getNameAbbreviation = (name?: string) => {
  return name?.[0]?.toUpperCase?.() || '#';
};

const formatDate = (date: string | Date | moment.Moment) => {
  const dt = moment(date);
  const today = moment().startOf('day');

  if (!dt.isValid()) return 'Recently';
  if (dt.isSame(today, 'day')) return dt.format('h:mm A');
  if (dt.isSame(today, 'year')) return dt.format('DD MMM');

  return dt.format('DD MMM, YYYY');
};

const formatAction = (action?: string) => {
  return action?.toLowerCase().replace(/_/g, ' ');
};

const onStarClick = () => {
  console.log('Star clicked');
};

const onRemoveClick = () => {
  console.log('Remove clicked');
};
</script>

<style lang="scss" scoped>
.chat-item {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 64px;
  column-gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: #FFFFFF;
  cursor: pointer;

  &:hover {
    background-color: #F2F2F2;
  }

  &--active {
    background-color: #EAEFFF;
  }

  &__avatar {
    display: flex;
    flex: none;
    width: 48px;
    height: 48px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: auto;
    row-gap: 2px;
    max-width: 214px;
  }

  &__recipient, &__message {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    flex: auto;
    width: 100%;
  }

  &__star, &__message-icon {
    display: flex;
    align-items: start;
    justify-content: start;
    flex: none;
    width: 12px;
    height: 12px;

    & svg {
      height: 12px;
      width: 12px;
      aspect-ratio: 1 / 1;
    }
  }

  &__name {
    flex: auto;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1A1B22;
  }

  &__badge {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #D62F2F;
    color: #FFFFFF;
    padding: 2px 3px;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
  }

  &__text {
    flex: initial;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #4F5366;

    &--unread {
      font-weight: 600;
      color: #1A1B22;
    }

    &--action {
      text-transform: capitalize;
    }
  }

  &__date {
    display: flex;
    flex: none;
    justify-content: end;
    margin-left: auto;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #A7A9B3;
    white-space: nowrap;
  }

  &__context-menu {
    display: flex;
    position: absolute;
    height: 24px;
    column-gap: 4px;
    right: 0;
    top: calc(50% - 12px);

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      padding: 4px;
      background-color: #FFFFFF;
      cursor: pointer;
    }
  }
}
</style>
