import type { ChatStoreConfig, PusherConfig, StringifiedPusherConfig } from '../stores/chat';
import { useUserStore } from '../stores/user';
import { useChatStore } from '../stores/chat';

const { user, isAuthorized, authorize, logout } = useUserStore();
const { init, destroy } = useChatStore();

export const
  controllerMount = async (jwt: string, api: string, pusherProp: PusherConfig | StringifiedPusherConfig) => {
    const pusher = typeof pusherProp === 'string' ? JSON.parse(pusherProp) : pusherProp;

    if (!isAuthorized.value) {
      await authorize(jwt, api);
    }

    if (isAuthorized.value) {
      await init({
        jwt,
        customer: user.value?.['id'] ?? null,
        baseUrl: api,
        pusher,
      } as ChatStoreConfig);
    }
  },
  controllerUnmount = () => {
    logout();
    destroy();
  };
