<template>
  <div class="chat-list">
    <div class="chat-list__title">Messages</div>
    <div class="chat-list__search">
      <UIInput
        type="text"
        size="sm"
        placeholder="Search"
        class="chat-list__search-input"
        :value="inputValue"
        has-clear-button
        @input="onInput"
        @clear="onClear"
      >
        <template #slotLeft>
          <div class="chat-list__search-input-magnifier">
            <IconMagnifier width="18" height="18"/>
          </div>
        </template>
      </UIInput>
    </div>
    <div class="chat-list__list">
      <template v-if="prefilteredChatList?.length">
        <ChatItem
          v-for="chat in prefilteredChatList || []"
          :key="chat?.key"
          :id="chat?.id"
          :name="chat?.name"
          :action="chat?.action"
          :message="chat?.message"
          :type="chat?.messageType"
          :unread="chat?.unreadCount"
          :active="chat?.id === getActiveChatId"
          :date="chat?.createdAt"
          @click.prevent="onClick(chat?.id)"
        >
          <img v-if="chat?.logo" :src="chat?.logo"/>
        </ChatItem>
      </template>
      <template v-else>
        <NotFound v-if="!!inputValue"/>
        <NoMessage v-else/>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconMagnifier from '../assets/images/icons/magnifier.svg';

import { type ComputedRef, computed, ref } from 'vue';
import { UIInput } from '@investorlift/www-investorlift-ui';
import ChatItem from './ChatItem.vue';
import NoMessage from './NoMessage.vue';
import NotFound from './NotFound.vue';
import { type Chat, useChatStore } from '../stores/chat';

const { chatList, getActiveChatId, selectChat } = useChatStore();

const inputValue = ref('');

const prefilteredChatList: ComputedRef<Chat[]> = computed(() => {
  if (!inputValue.value) {
    return chatList.value;
  }

  return chatList.value?.filter((chat: Chat) => {
    const customerName = chat?.name || '';

    return customerName.toLowerCase().includes(inputValue.value.toLowerCase());
  });
});

const onInput = (e: InputEvent) => {
  inputValue.value = (e.target as HTMLInputElement).value;
};

const onClear = () => {
  inputValue.value = '';
};

const onClick = (chatId: any) => {
  selectChat(chatId);
};
</script>

<style lang="scss" scoped>
.chat-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;

  &__title {
    padding: 0 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #1A1B22;
  }

  &__search {
    padding: 22px 16px 12px 16px;
  }

  &__search-input {
    width: 100%;

    &-magnifier {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: 3px;
    width: 100%;
    padding: 0 16px;
    overflow-y: auto;
  }
}
</style>
