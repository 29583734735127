import moment from 'moment';

const LOCALE_DEFAULT = 'en';

moment.locale(LOCALE_DEFAULT);

export const resolveTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  catch (e) {
    return null;
  }
};

export default moment;
