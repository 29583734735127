<template>
  <div class="avatar">
    <div class="avatar__content">
      <slot>#</slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid #BFBFBF;
  border-radius: 24px;
  color: #FFFFFF;
  background-color: #BFBFBF;
  overflow: hidden;

  &__content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;

    & > :deep(img) {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: #FFFFFF;
    }
  }
}
</style>
