import { createCustomElement, createPlugin } from '../../util/helpers';
import Messenger from './components/Messenger.vue';
import MessengerActionButton from './components/MessengerActionButton.vue';

const
  MessengerPlugin = createPlugin('Messenger', Messenger),
  MessengerActionButtonPlugin = createPlugin('MessengerActionButton', MessengerActionButton),
  MessengerElement = createCustomElement(Messenger),
  MessengerActionButtonElement = createCustomElement(MessengerActionButton);

export {
  Messenger,
  MessengerActionButton,
  MessengerPlugin,
  MessengerActionButtonPlugin,
  MessengerElement,
  MessengerActionButtonElement,
};
