<template>
  <div class="offer">
    <div class="offer__row">
      <div class="offer__label">Offer amount</div>
      <div class="offer__value">${{ formatNumber(props.price) }}</div>
    </div>
    <div class="offer__row">
      <div class="offer__label">Buyer name</div>
      <div class="offer__value">{{ props.name }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  price: number | string;
  name: string;
}>();

const formatNumber = (n: number | string) => {
  if (!n || typeof n === 'string') return n;
  return n.toLocaleString('en-US');
};
</script>

<style lang="scss" scoped>
.offer {
  display: flex;
  flex-direction: column;
  padding: 8px 4px;
  gap: 4px;

  &__row {
    display: flex;
    gap: 4px;
    flex: none;
  }

  &__label {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #4F5366;
  }

  &__value {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: #1A1B22;
  }
}
</style>
