import axios, {type AxiosInstance, type AxiosResponse} from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { computed, ref } from 'vue';
import moment, { resolveTimezone } from '../utils/moment';

type Nullable<T> = T | null;

type Stringified<T> = string & {
  [P in keyof T]: { "_ value": T[P] }
};

export interface PusherConfig {
  key: string,
  cluster: string,
  authEndpoint?: string,
  channel?: string,
}

export type StringifiedPusherConfig = Stringified<PusherConfig>;

export interface ChatStoreConfig {
  jwt: string,
  customer: any,
  baseUrl: string,
  interval?: number,
  pusher: PusherConfig,
}

// NOTE:
// the direction of messages is vice versa:
// "inbound" means inbound for Admin, for Deals it will be outbound de facto
export enum MessageType {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

export interface ChatItemOrigin {
  id: any,
  account: {
    id: any,
    title: string,
    deals_site_settings: {
      deals_emails_logo: string,
    },
  },
  last_message: {
    type: MessageType,
    action: string,
    created_at: string,
    calculated_send_datetime: string,
    calculated_body: string,
    customer: {
      full_name: string,
    },
  }
  customer_unread_count: number,
}

export interface ChatListOrigin {
  data: ChatItemOrigin[],
  links: {
    next: string | null,
  },
}

export interface Chat {
  key: any,
  id: any,
  name: string,
  logo: string,
  messageType: MessageType,
  action: string,
  createdAt: moment.Moment,
  message: string,
  unreadCount: number,
}

export interface MessageItemOrigin {
  id: any,
  type: MessageType,
  action: string,
  channel_type: string | null,
  created_at: string,
  calculated_send_datetime: string,
  calculated_body: string,
  is_read: boolean,
  customer: {
    full_name: string,
  },
  property?: {
    id: any,
    created_at: string,
    property_page_url: string,
    main_image: {
      thumbnail_url: string,
    },
    price: number,
    arv_estimate?: number,
    full_address: string,
    bedrooms: number | string,
    bathrooms: number | string,
    sq_footage: number,
  },
  offer?: {
    price: number,
    buyer: string,
  },
}

export interface MessageItemPusher extends MessageItemOrigin {
  account: {
    id: any,
    title: string,
  },
}

export interface MessageListOrigin {
  data: MessageItemOrigin[],
  links: {
    next: string | null,
  },
}

export interface Offer {
  price: number,
  buyer: string,
}

export interface Property {
  id: any,
  createdAt: moment.Moment,
  url: string,
  thumb: string,
  price: number,
  arv: number | null,
  address: string,
  beds: number | string,
  baths: number | string,
  sqft: number,
}

export interface Message {
  id: any,
  type: MessageType,
  action: string,
  channel: string | null,
  createdAt: moment.Moment,
  message: string,
  isRead: boolean,
  property: Nullable<Property>,
  offer: Nullable<Offer>,
}

const DEFAULT_ENDPOINT: string = '/broadcasting/auth';
const DEFAULT_CHANNEL: string = 'App';
const DEFAULT_INTERVAL: number = 5000;
const PAGES_PRELOAD_LIMIT: number = 2;
const EVENT_INBOUND_MESSAGE: string = '.TextMessageInboundSent';
const EVENT_OUTBOUND_MESSAGE: string = '.TextMessageOutboundSent';

class ChatStore {
  private chatListStore = ref(new Map() as Map<any, Chat>);
  private messageListStore = ref(new Map() as Map<any, Message>);
  private activeChatId = ref(null as any);

  public chatList = computed(() => (
    Array
      .from(this.chatListStore.value.values())
      .sort((a, b) => +b.createdAt - +a.createdAt)
  ));
  public getActiveChatId = computed(() => this.activeChatId.value);
  public getActiveChat = computed(() => this.chatListStore.value.get(this.activeChatId.value));
  public unreadCount = computed(() => (
    Array
      .from(this.chatListStore.value.values())
      .reduce((total, chat) => total + chat.unreadCount, 0)
  ));

  public messageList = computed(() => (
    Array
      .from(this.messageListStore.value.values())
      .sort((a, b) => +b.createdAt - +a.createdAt)
  ));

  private initialized = ref(false);
  public isInitialized = computed(() => this.initialized.value);

  private axios: null | AxiosInstance = null;
  private pusher: null | Echo<any> = null;
  private channel: null | any = null;

  private token: string = '';
  private customer: any = null;
  private baseUrl: string = '';
  private interval: number = DEFAULT_INTERVAL;
  private pusherConfig: PusherConfig | null = null;

  private currentPage: number = 0;
  private hasNextPage: boolean = true;
  private unreadQueue: any[] = [];

  private fetchChatListJob: any = null;
  private readMessagesJob: any = null;

  public init = async (config: ChatStoreConfig) => {
    this.token = config.jwt;
    this.customer = config.customer;
    this.baseUrl = config.baseUrl;
    this.interval = config.interval || DEFAULT_INTERVAL;
    this.pusherConfig = config.pusher;

    await this.initJobs();
    await this.initSelectChat();
  }

  private getChannelName = () => `${this.pusherConfig?.channel || DEFAULT_CHANNEL}.Models.Customer.${this.customer}`;

  public initJobs = async () => {
    // prevent double initialization
    if (this.initialized.value) return;

    // semaphore is up
    this.initialized.value = true;
    console.log('Chat store initialized');

    // init axios instance
    this.axios = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Authorization': `Bearer ${this.token}`,
        'X-Client-Timezone': resolveTimezone(),
      }
    });

    // init pusher instance
    const url = new URL(this.baseUrl);
    this.pusher = new Echo({
      Pusher,
      key: this.pusherConfig?.key,
      cluster: this.pusherConfig?.cluster,
      broadcaster: 'pusher',
      forceTLS: true,
      bearerToken: this.token,
      authEndpoint: this.pusherConfig?.authEndpoint || `${url.origin}${DEFAULT_ENDPOINT}`,
    });

    this.channel = this.pusher.private(this.getChannelName());

    this.channel.subscribe();
    this.channel.listen(EVENT_INBOUND_MESSAGE, this.handlePusherMessage);
    this.channel.listen(EVENT_OUTBOUND_MESSAGE, this.handlePusherMessage);

    await this.setupChatListJob();
    await this.setupReadMessagesJob();
  }

  public destroy = () => {
    this.chatListStore.value.clear();
    this.messageListStore.value.clear();
    this.activeChatId.value = null;

    this.channel.stopListening(EVENT_INBOUND_MESSAGE);
    this.channel.stopListening(EVENT_OUTBOUND_MESSAGE);
    this.channel.unsubscribe();

    clearTimeout(this.fetchChatListJob);
    clearInterval(this.readMessagesJob);
    this.fetchChatListJob = null;
    this.readMessagesJob = null;

    // semaphore is down
    this.initialized.value = false;

    console.log('Chat store destroyed');
  }

  private initSelectChat = async () => {
    const params = new URLSearchParams(window?.location?.search || '');
    const chatId = Number(params.get('account'));

    if (this.chatListStore.value.has(chatId)) await this.selectChat(chatId);
  }

  public selectChat = async (chatId: any) => {
    this.activeChatId.value = chatId;
    this.messageListStore.value.clear();
    this.currentPage = 0;
    this.hasNextPage = true;
    await this.fetchMessageList();
    this.calculateUnreadCount(); // recalculate the unread count to mitigate consequences of the inconsistency
  }

  public fetchChatList = async (page = 1) => {
    const url = `/customers/${this.customer}/chats`;
    const params = {
      page,
      with: [
        'account.deals_site_settings',
        'last_message.text_message_body',
        'last_message.inquiry',
      ].join(';')
    }

    try {
      const response: AxiosResponse | null = await this.axios?.get(url, { params }) || null;
      const result: ChatListOrigin = response?.data;

      for (const chatItem of result?.data || []) {
        const chat: Chat = this.transformChat(chatItem);

        if (chat.id) this.chatListStore.value.set(chat.id, chat);
      }

      if (result?.links?.next) await this.fetchChatList(page + 1);
    }
    catch (e) {
      console.warn('Chat list fetching failed', e);
    }
  }

  public fetchMessageList = async () => {
    if (!this.hasNextPage) return;

    const page = ++this.currentPage;
    const activeChatId = this.activeChatId.value;
    const url = `/customers/${this.customer}/text-messages`;
    const params = {
      page,
      'filter[account_id]': activeChatId,
      with: [
        'inquiry',
        'offer',
        'property.main_image',
      ].join(';')
    };

    try {
      const response: AxiosResponse | null = await this.axios?.get(url, { params }) || null;
      const result: MessageListOrigin = response?.data;
      if (this.activeChatId.value === activeChatId) {
        for (const messageItem of result?.data || []) {
          const message: Message = this.transformMessage(messageItem);

          if (message.id) this.messageListStore.value.set(message.id, message);
        }

        this.hasNextPage = !!result?.links?.next;
        if (this.hasNextPage && this.currentPage < PAGES_PRELOAD_LIMIT) await this.fetchMessageList();
      }
    }
    catch (e) {
      console.warn('Message list fetching failed', e);
    }
  }

  private calculateUnreadCount = () => {
    const activeChatId = this.activeChatId.value;
    const store = this.messageListStore.value;
    const unreadCount = Array
      .from(store.values())
      .reduce((total, message) => {
        if (!message.isRead && message.type === MessageType.OUTBOUND) total++;

        return total;
      }, 0);

    // persist the recalculation
    if (activeChatId === this.activeChatId.value) {
      const chat = this.chatListStore.value.get(activeChatId);
      if (chat) chat.unreadCount = unreadCount;
    }
  }

  private transformChat = (chatItem: ChatItemOrigin): Chat => {
    return {
      key: chatItem?.id,
      id: chatItem?.account?.id,
      name: chatItem?.account?.title,
      logo: chatItem?.account?.['deals_site_settings']?.['deals_emails_logo'],
      messageType: chatItem?.['last_message'].type,
      action: chatItem?.['last_message']?.['action'],
      createdAt: moment(chatItem?.['last_message']?.['calculated_send_datetime'] ?? chatItem?.['last_message']?.['created_at']),
      message: chatItem?.['last_message']?.['calculated_body'],
      unreadCount: chatItem?.['customer_unread_count']
    };
  }

  private transformMessage = (messageItem: MessageItemOrigin): Message => {
    return {
      id: messageItem?.id,
      type: messageItem?.type,
      action: messageItem?.action,
      channel: messageItem?.['channel_type'],
      createdAt: moment(messageItem?.['calculated_send_datetime'] ?? messageItem?.['created_at']),
      message: messageItem?.['calculated_body'],
      isRead: messageItem?.['is_read'],
      property: this.transformProperty(messageItem?.property),
      offer: this.transformOffer(messageItem?.offer),
    };
  }

  private transformOffer = (offer: any): Nullable<Offer> => {
    if (!offer) return null;

    return {
      price: offer?.price,
      buyer: offer?.buyer
    }
  }

  private transformProperty = (property: any): Nullable<Property> => {
    if (!property) return null;

    return {
      id: property?.id,
      createdAt: moment(property?.['created_at']),
      url: property?.['property_page_url'],
      thumb: property?.['main_image']?.['thumbnail_url'],
      price: property?.price,
      arv: property?.['arv_estimate'] ?? null,
      address: property?.['full_address'],
      beds: property?.bedrooms,
      baths: property?.bathrooms,
      sqft: property?.sq_footage
    }
  }

  private handlePusherMessage = async (event: MessageItemPusher[]) => {
    const [data] = event;
    const chatId = data.account?.id;
    const message = this.transformMessage(data);

    await this.addMessage(chatId, message);
  }

  private addMessage = async (chatId: any, message: Message) => {
    const chat = this.chatListStore.value.get(chatId);

    if (chatId && chat && !this.messageListStore.value.has(message?.id)) {
      // 1. Process the message list
      if (this.activeChatId.value === chatId && message?.id) this.messageListStore.value.set(message.id, message);

      // 2. Process the chat list
      chat.action = message.action;
      chat.message = message.message;
      chat.messageType = message.type;
      chat.createdAt = message.createdAt;
      if (!message.isRead && message.type === MessageType.OUTBOUND) chat.unreadCount++;
    }
    else await this.fetchChatList();
  }

  private readMessages = async () => {
    const queue = this.unreadQueue.splice(0, Infinity);
    const url = `/customers/${this.customer}/text-message-service/read-state`;

    try {
      await this.axios?.put(url, {
        'is_read': true,
        'text_messages_ids': queue.map(([id, ]) => id),
      });

      // update the message list
      for (const [id, chatId] of queue) {
        const message = this.messageListStore.value.get(id);
        if (message) message.isRead = true;

        const chat = this.chatListStore.value.get(chatId);
        if (chat && chat.unreadCount > 0) chat.unreadCount--;
      }
    }
    catch (e) {
      console.warn('Message labeling as read failed', e);
      this.unreadQueue.push(...queue);
    }
  }

  private async setupChatListJob() {
    if (this.fetchChatListJob) return;

    if (this.initialized.value) await this.fetchChatList();
    else {
      this.fetchChatListJob = setTimeout(async () => this.setupChatListJob(), this.interval);
    }
  }

  private async setupReadMessagesJob() {
    if (this.readMessagesJob) return;

    this.readMessagesJob = setInterval(async () => {
      if (this.initialized.value && this.unreadQueue.length) await this.readMessages();
    }, this.interval);
  }

  public publishMessage = async (message: string) => {
    const chatId = this.activeChatId.value;
    const url = `/customers/${this.customer}/accounts/${chatId}/text-messages`;

    try {
      const response: AxiosResponse | null = await this.axios?.post(url, { message }) || null;
      const messageItem: MessageItemOrigin = response?.data;

      await this.addMessage(chatId, this.transformMessage(messageItem));
    }
    catch (e) {
      console.warn('Message publishing failed', e);
    }
  }

  public readMessage = async (id: string) => {
    const chatId = this.activeChatId.value;
    this.unreadQueue.push([id, chatId]);
  }
}

const chatStore = new ChatStore();

export function useChatStore() {
  return chatStore;
}
